import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

export default () => {
  const thisPostUrl = typeof window !== "undefined" ? window.location.href : ""
  const pageTitle =
    typeof document !== "undefined" ? document.title : "Millionaire Millennial"
  const data = useStaticQuery(graphql`
    query shareImages {
      allFile(filter: { absolutePath: { regex: "/share-icons/" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 50, height: 50) {
                originalName
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div id="share-buttons">
      {data.allFile.edges.map(({ node }) => {
        let thisImage = node.childImageSharp.fixed
        let thisImageName = thisImage.originalName
        let linkHref = "",
          onClick = () => {
            return false
          }
        switch (thisImageName) {
          case "buffer.png":
            linkHref = `https://bufferapp.com/add?url=${thisPostUrl}&text=${pageTitle}`
            break
          case "email.png":
            linkHref = `mailto:?subject=${pageTitle}&body=Check this out - ${thisPostUrl}`
            break
          case "facebook.png":
            linkHref = `http://www.facebook.com/sharer.php?u=${thisPostUrl}`
            break
          case "linkedin.png":
            linkHref = `https://www.linkedin.com/sharing/share-offsite/?url=${thisPostUrl}`
            break
          case "pinterest.png":
            linkHref = `https://pinterest.com/pin/create/button/?url=${thisPostUrl}&media=&description=${pageTitle}`
            break
          case "print.png":
            linkHref = "#"
            onClick = e => {
              window.print()
              e.preventDefault()
              return false
            }
            break
          case "reddit.png":
            linkHref = `http://reddit.com/submit?url=${thisPostUrl}&title=${pageTitle}`
            break
          case "twitter.png":
            linkHref = `https://twitter.com/share?url=${thisPostUrl}&text=Check%20This%20Out&hashtags=LevelLifeUp`
            break

          default:
            break
        }
        return (
          <a
            href={linkHref}
            onClick={onClick}
            key={thisImageName}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image fixed={thisImage} alt={thisImageName} />
          </a>
        )
      })}
    </div>
  )
}
