import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/share"
import { Box } from "theme-ui"
// import commentBox from "commentbox.io"

import "../styles/blog-post.scss"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  // useEffect(() => {
  //   const removeCommentBox = commentBox("5735301003608064-proj")
  //   return () => {
  //     removeCommentBox()
  //   }
  // }, [])

  const styles = {
    content: {
      bg: "contentBg",
      borderRadius: `lg`,
      boxShadow: `1px 1px 5px 0 rgba(1,1,1,.05)`,
    },
  }

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const { image } = post.frontmatter
  const ogImagePath = image && image.childImageSharp.fixed.src

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        isBlogPost={true}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={ogImagePath}
      />
      <div className="blog-container">
        <Box className="main-content" sx={styles.content}>
          <article>
            <header>
              <h1>{post.frontmatter.title}</h1>
              <p>{post.frontmatter.date}</p>
            </header>
            {post.frontmatter.image &&
              post.frontmatter.image.childImageSharp.fluid && (
                <Img
                  fluid={post.frontmatter.image.childImageSharp.fluid}
                  style={{ marginBottom: "1rem",maxWidth:550, margin:'auto'}}
                />
              )}
              
            <section dangerouslySetInnerHTML={{ __html: post.html }} />

            {(previous || next) && (
              <nav>
                <ul className="blog-bottom-nav">
                  <div>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </div>
                  <div>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </div>
                </ul>
              </nav>
            )}
            <Bio />
            <div
              style={{
                textAlign: "center",
                marginBottom: -8,
                color: "hsla(0,0%,0%,0.2)",
              }}
            >
              Share
            </div>
            <hr
              style={{
                marginBottom: 4,
              }}
            />

            <ShareButtons />

            <hr
              style={{
                marginTop: 5,
                marginBottom: "1rem",
              }}
            />

            <div className="commentbox" />
          </article>
        </Box>
        <Box className="sidebar">
          <div id="share">
            Share
            <ShareButtons />
          </div>
        </Box>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fixed {
              src
            }
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
