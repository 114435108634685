/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            instagram
            youtube
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: 0,
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          margin:'auto 1rem',
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Written by <strong>{author}</strong> who lives in Dallas and helps
        people enjoy life, make money, and build cool stuff. You should follow
        him on{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://instagram.com/${social.instagram}`}
        >
          Instagram
        </a>{" "}
        /{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.youtube.com/channel/${social.youtube}`}
        >
          YouTube
        </a>
      </p>
    </div>
  )
}

export default Bio
